import type { GetPaymentMethods } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import type {
  GetPaymentMethodsOptions,
  GetPaymentMethodsOptionsVNext,
  LegacyGetPaymentMethodsOptions,
} from './getPaymentMethods.specs'

export function usePaymentMethods() {
  const $httpFetch = useHttpFetch()

  async function legacyGetPaymentMethods({
    countryCode,
    listings,
    bagPrice,
    insuranceSubscription,
    features,
  }: LegacyGetPaymentMethodsOptions) {
    const data = await $httpFetch('/payment/payment_methods', {
      query: {
        country_code: countryCode,
        listings: listings?.join(','),
        bag_price: bagPrice?.amount,
        insurance_subscription: insuranceSubscription,
        features: features?.join(','),
      },
    })

    return camelizeKeys(data)
  }

  async function getPaymentMethods({
    tenant,
    countryCode,
    listings,
    bagPrice,
    features,
  }: GetPaymentMethodsOptionsVNext) {
    const data = await $httpFetch('/payment/payment-methods', {
      query: {
        tenant,
        country: countryCode,
        client_platform: 'WEB',
        listings_ids: listings?.join(','),
        price: bagPrice?.amount,
        features: features?.join(','),
      },
    })

    // TODO [PAYIN-4850] map the response to the old format
    // The following assertion is wrong, and only here to make typecheck pass
    return data as GetPaymentMethods.Response
  }

  return async (options: GetPaymentMethodsOptions) => {
    if (options.tenant === 'TRADEIN') {
      return getPaymentMethods(options)
    }

    return legacyGetPaymentMethods(options)
  }
}
